<template>
  <div class="top-info">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="10" class="wides top-strip pb-0" style="position: relative;">
          <div class="public-prof" style="display: flex; justify-content: space-between; align-items: center;">
            <!-- Back arrow aligned to left -->
            <v-btn id="back-arrow" class="ma-1" rounded text outlined @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <!-- Grouped elements aligned to right -->
            <div style="display: flex; align-items: center;">
              <!-- Tooltip and switch -->
              <div class="tooltip-container" style="max-width: 100px;">
                <v-tooltip bottom color="light-blue darken-4">
                  <template #activator="{ on }">
                    <v-switch
                      id="email-switch"
                      v-model="localIsSearchable"
                      :label="$t('Pages.Profile.searchable')"
                      color="accent"
                      class="margin-chip"
                      v-on="on"
                      @change="onChangeSearchable"
                    />
                  </template>
                  <span v-html="$t('Pages.Profile.hide_show')" />
                </v-tooltip>
              </div>

              <!-- View public profile button -->
              <v-btn
                id="view-public-profile-btn"
                :to="{ name: 'inner-profile', params: { id: user.userId } }"
                color="primary"
                rounded
                class="ma-2"
                v-text="$t('Pages.Profile.public_profile')"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { CHANGE_SEARCHABLE } from '@/store/actions.type'

export default {
  name: 'EditProfileTopBar',
  props: {
    route: {
      type: String,
      default: 'profile'
    }
  },
  data() {
    return {
      localIsSearchable: false
    }
  },
  computed: {
    ...mapState(['profile', 'user'])
  },
  watch: {
    'profile.hidden': {
      immediate: true,
      handler(newVal) {
        this.localIsSearchable = !newVal
      }
    }
  },
  methods: {
    onChangeSearchable() {
      this.$store.dispatch(CHANGE_SEARCHABLE, this.localIsSearchable).then(() => {
      }, err => {
        console.error(err)
        this.$root.$emit('errorMessage', err.response)
      })
    }
  }
}
</script>