var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.profile)?_c('ProfileTitle',{attrs:{"profile":_vm.profile,"title":_vm.$t('Pages.Profile.skills_tools'),"array":"skills","button-id":"skill","help":true},on:{"button-event":_vm.addOtherSkill,"icon-event":_vm.openSkillDialog}}):_vm._e(),(_vm.profile.skills && _vm.profile.skills.length)?_c('p',{staticClass:"pl-0",domProps:{"textContent":_vm._s(_vm.$t('Pages.Profile.highlight'))}}):_vm._e(),_c('transition-group',{staticClass:"row wrap resume-cards",attrs:{"id":"skill-container","name":"slide-y-transition","tag":"v-layout"}},_vm._l((_vm.profile.skills),function(skill,index){return _c('v-col',{key:'starred-' + skill.skill,attrs:{"cols":"12","sm":"4"}},[_c('ProfileCard',{staticClass:"fab-30-px",attrs:{"title":skill.skill,"fill-height":true,"menu-id":'more-skill-' + index,"list":[
          skill.monthsOfExperience ? {
            id: 'edit-skill-' + index,
            clickEvent: 'edit-item',
            text: _vm.$t('Common.edit')
          } : null,
          {
            id: 'delete-skill-' + index,
            clickEvent: 'delete-item',
            text: _vm.$t('Common.delete')
          } ]},on:{"edit-item":function($event){_vm.skillList.push(skill.skill), _vm.currentSkill = { skill: skill.skill, monthsOfExperience: skill.monthsOfExperience, deviantMonths: skill.monthsOfExperience, index: index}, _vm.editSkillTimeDialog = true},"delete-item":function($event){_vm.itemToDelete = skill; _vm.deleteSkillDialog = true}}},[_c('span',{attrs:{"slot":"star"},slot:"star"},[(skill.star)?_c('v-btn',{staticClass:"elevation-1 avatar-chip fab-scaling-icon-hover fav-star",attrs:{"id":'starred-' + index,"color":"success","fab":"","absolute":"","small":""},on:{"click":function($event){skill.star = !skill.star; _vm.$emit('save-content', skill, 'skills/star')}}},[_c('v-icon',{staticClass:"star-hover cursor-pointer"},[_vm._v(" star ")])],1):_c('v-btn',{staticClass:"elevation-1 avatar-chip fab-scaling-icon-hover fav-star",attrs:{"disabled":_vm.hasFiveStars,"color":"success","fab":"","absolute":"","small":""},on:{"click":function($event){skill.star = !skill.star; _vm.$emit('save-content', skill, 'skills/star')}}},[_c('v-icon',{staticClass:"star"},[_vm._v(" star_border ")])],1)],1),_c('div',{attrs:{"slot":"tooltip"},slot:"tooltip"},[_c('div',[(skill.monthsOfExperience !== null && skill.monthsOfExperience !== undefined)?_c('span',[_c('v-tooltip',{attrs:{"color":"light-blue darken-4","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({staticClass:"skill-time-value"},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" schedule ")]),(skill.monthsOfExperience >= 12)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.formatYear(skill.monthsOfExperience))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.formatRemainingMonths(skill.monthsOfExperience)))])],1)]}}],null,true)},[_c('span',[_vm._v("Experience with skill")])])],1):_vm._e()]),_c('div',[(skill.monthsSinceLastUsed !== null && skill.monthsSinceLastUsed !== undefined)?_c('v-tooltip',{attrs:{"color":"light-blue darken-4","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({staticClass:"skill-time-value"},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" update ")]),(skill.monthsSinceLastUsed >= 12)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.formatYear(skill.monthsSinceLastUsed))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.formatRemainingMonths(skill.monthsSinceLastUsed)))])],1)]}}],null,true)},[_c('span',[_vm._v("Time since last use")])]):_vm._e()],1),(skill.monthsOfExperience === null || skill.monthsOfExperience === undefined)?_c('div',{staticStyle:{"height":"1.56rem"}}):_vm._e()])])],1)}),1),_c('transition',{attrs:{"name":"slide-y-transition"}},[(!_vm.profile.skills || !_vm.profile.skills.length)?_c('EmptySectionText',{attrs:{"icon":"library_books","title":_vm.$t('Pages.Profile.work_related')},on:{"click-event":_vm.openAdditionalSkillDialog}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.deleteSkillDialog),callback:function ($$v) {_vm.deleteSkillDialog=$$v},expression:"deleteSkillDialog"}},[_c('CustomDialog',{attrs:{"title-icon":"delete","title":_vm.$t('Pages.Profile.delete_skill'),"second-title":_vm.$t('Pages.Profile.delete_skill_text'),"action-button":{
        class: 'deleteAction',
        id: 'delete-skill-confirm-btn',
        text: _vm.$t('Common.delete')
      },"close-button":{
        id: 'delete-skill-cancel-btn',
        text: _vm.$t('Common.cancel')
      }},on:{"close-event":function($event){_vm.deleteSkillDialog = false},"perform-action":_vm.handleDelete}})],1),_c('v-dialog',{model:{value:(_vm.skillDialog),callback:function ($$v) {_vm.skillDialog=$$v},expression:"skillDialog"}},[_c('CustomDialog',{attrs:{"title-icon":"add_circle","title":_vm.$t('Pages.Profile.non_work'),"second-title":_vm.$t('Pages.Profile.non_work_text')}},[_c('div',{attrs:{"slot":"custom"},slot:"custom"},[_c('v-form',{ref:"autoCompleteSkill",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"id":"other-skill-autocomplete","outlined":"","rounded":"","dense":"","color":"accent","label":_vm.$t('Pages.Profile.skill_or_tool')},model:{value:(_vm.skillSelect),callback:function ($$v) {_vm.skillSelect=$$v},expression:"skillSelect"}})],1)],1),_c('div',{staticClass:"button-container",attrs:{"slot":"buttons"},slot:"buttons"},[_c('v-btn',{attrs:{"id":"close-skill-btn","text":"","rounded":""},domProps:{"textContent":_vm._s(_vm.$t('Common.cancel'))},on:{"click":function($event){_vm.skillDialog = false}}}),_c('v-btn',{staticClass:"cta-btn",attrs:{"id":"save-save-btn","text":"","rounded":""},domProps:{"textContent":_vm._s(_vm.$t('Common.save'))},on:{"click":function($event){_vm.currentSkill.skills.push(_vm.skillSelect); _vm.onSaveSkill(_vm.currentSkill.skills[0]); _vm.skillSelect = ''}}})],1)])],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.additionalSkillDialog),callback:function ($$v) {_vm.additionalSkillDialog=$$v},expression:"additionalSkillDialog"}},[_c('CustomDialog',{attrs:{"title-icon":"info","title":_vm.$t('Pages.Profile.non_work_related'),"second-title":_vm.$t('Pages.Profile.non_work_1'),"text":_vm.$t('Pages.Profile.non_work_2'),"close-button":{
        id: 'close-non-related-btn',
        text: _vm.$t('Common.close')
      }},on:{"close-event":function($event){_vm.additionalSkillDialog = false}}})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.editSkillTimeDialog),callback:function ($$v) {_vm.editSkillTimeDialog=$$v},expression:"editSkillTimeDialog"}},[_c('CustomDialog',{attrs:{"title":_vm.currentSkill.skill,"title-icon":"schedule","second-title":_vm.$t('Pages.Profile.edit_amount'),"action-button":{
        class: 'cta-btn',
        id: 'edit-confirm-btn',
        text: _vm.$t('Common.save')
      },"close-button":{
        id: 'edit-cancel-btn',
        text: _vm.$t('Common.cancel')
      }},on:{"close-event":function($event){_vm.editSkillTimeDialog = false},"perform-action":function($event){return _vm.editWorkedMonths(_vm.currentSkill)}}},[_c('div',{attrs:{"slot":"custom"},slot:"custom"},[(_vm.currentSkill.deviantMonths !== null)?_c('h4',{staticClass:"text-center mt-4"},[_vm._v(" "+_vm._s(_vm.formatYear(_vm.currentSkill.deviantMonths))+" "+_vm._s(_vm.formatRemainingMonths(_vm.currentSkill.deviantMonths))+" ")]):_vm._e(),_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{staticClass:"mr-1",attrs:{"light":"","depressed":"","rounded":"","disabled":_vm.currentSkill.deviantMonths === 0 || _vm.currentSkill.deviantMonths < 6},on:{"click":function($event){_vm.currentSkill.deviantMonths -= 6}}},[_vm._v(" -6 "+_vm._s(_vm.$t('Pages.Profile.months'))+" ")]),_c('v-btn',{attrs:{"light":"","depressed":"","rounded":""},on:{"click":function($event){_vm.currentSkill.deviantMonths += 6}}},[_vm._v(" +6 "+_vm._s(_vm.$t('Pages.Profile.months'))+" ")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }