<template>
  <div>
    <ProfileTitle
      v-if="profile"
      :profile="profile"
      :title="$t('Pages.Profile.experiences_assignments')"
      array="experiences"
      button-id="experience"
      @button-event="addExperience"
    />
    <transition-group
      id="experience-container"
      name="slide-y-transition"
      tag="v-layout"
      class="row wrap resume-cards resume-experience"
    >
      <v-col
        v-for="(experience, index) in profile.experiences"
        :key="experience.id"
        cols="12"
        sm="6"
      >
        <div
          is="ProfileCard"
          :title="experience.company"
          :subtitle="experience.jobTitle"
          :second-line-subtitle="getExperienceDates(experience)"
          :description="experience.description"
          :menu-id="'more-experience-' + index"
          :list="[
            {
              id: 'edit-experience-' + index,
              clickEvent: 'edit-item',
              text: $t('Common.edit')
            },
            {
              id: 'delete-experience-' + index,
              clickEvent: 'delete-item',
              text: $t('Common.delete')
            },
          ]"
          @edit-item="addExistingExperience(experience, index), getSkillsFromSkillsArray(experience.skills, false), experienceDialog = true"
          @delete-item="itemToDelete = experience; deleteExperienceDialog = true"
        >
          <div
            slot="custom"
          >
            <v-divider />
            <div
              v-for="(reference, i) in experience.references"
              id="reference.id"
              :key="reference.id"
            >
              <div class="reference-content">
                <span class="mr-2">
                  <v-icon class="mr-1">
                    person
                  </v-icon> {{ userHandler.firstName(reference) }} {{
                    userHandler.lastName(reference) }}
                  <v-tooltip
                    v-if="reference.approved === true"
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon v-on="on">
                        mdi-approval
                      </v-icon>
                    </template>
                    <span>{{ $t('Pages.Profile.approved_by') }}{{ userHandler.firstName(reference) }} {{ userHandler.lastName(reference) }}</span>
                  </v-tooltip>
                </span>&nbsp;
                <v-tooltip
                  bottom
                  color="light-blue darken-4"
                >
                  <template #activator="{ on }">
                    <v-icon
                      :id="'delete-ref-' + i"
                      class="delete"
                      v-on="on"
                      @click="deleteReferenceDialog = true; addReferenceExperience = experience; deleteReferenceId = reference.id"
                    >
                      highlight_off
                    </v-icon>
                  </template>
                  <span v-text="$t('Pages.Profile.delete_reference')" />
                </v-tooltip>
              </div>
              <v-divider class="mt-2" />
            </div>
            <p
              id="experience-add-ref"
              class="add-reference"
              @click="addReferenceDialog = true, addReferenceExperience = experience;"
            >
              <v-icon
                :class="{ 'ml-3' : experience.references && experience.references.length }"
                class="mr-2"
              >
                person_add
              </v-icon>{{ $t('Pages.Profile.add_reference') }}
            </p>
          </div>
        </div>
      </v-col>
    </transition-group>
    <transition name="slide-y-transition">
      <EmptySectionText
        v-if="!profile.experiences || !profile.experiences.length"
        icon="work"
        :title="$t('Pages.Profile.try_sticking')"
        :text="$t('Pages.Profile.pro_tip')"
      />
    </transition>
    <v-dialog
      v-model="deleteExperienceDialog"
      max-width="500"
    >
      <CustomDialog
        title-icon="delete"
        :title="$t('Pages.Profile.delete_experience')"
        :second-title="$t('Pages.Profile.delete_experience_text')"
        :action-button="{
          class: 'deleteAction',
          id: 'delete-experience-confirm-btn',
          text: $t('Common.delete')
        }"
        :close-button="{
          id: 'delete-exp-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="deleteExperienceDialog = false"
        @perform-action="handleDelete"
      />
    </v-dialog>
    <v-dialog v-model="experienceDialog">
      <v-form
        ref="experienceForm"
        v-model="isFormValid"
      >
        <div
          is="CustomDialog"
          title-icon="add_circle"
          :title="$t('Pages.Profile.add_experience')"
          :second-title="$t('Pages.Profile.focus_on')"
        >
          <div slot="custom">
            <div class="wrapper-div">
              <v-text-field
                id="current-experience-position"
                v-model="currentExperience.jobTitle"
                outlined
                rounded
                dense
                color="accent"
                :rules="[rules.required]"
                required
                :label="$t('Pages.Profile.job_title')"
              />
              <v-text-field
                id="current-experience-company"
                v-model="currentExperience.company"
                :maxlength="30"
                :counter="30"
                color="accent"
                outlined
                rounded
                :rules="[rules.required]"
                dense
                :label="$t('Pages.Profile.company_name')"
              />
              <v-select
                v-model="currentExperience.isAssignment"
                :items="[
                  { text: $t('Pages.Profile.employee'), value: false },
                  { text: $t('Pages.Profile.consultant'), value: true }
                ]"
                color="accent"
                outlined
                rounded
                dense
                hide-details
                :label="$t('Pages.Profile.form_of_employment')"
              />
              <v-checkbox
                id="current-ongoing-checkbox"
                v-model="currentExperience.ongoing"
                :label="$t('Pages.Profile.ongoing')"
                color="accent"
                @change="checkInput"
              />
            </div>
            <div class="wrapper-div">
              <v-row>
                <v-col cols="6">
                  <v-select
                    id="current-experience-start-month"
                    v-model="currentExperience.startMonth"
                    :items="months"
                    color="accent"
                    :label="$t('Pages.Profile.start_month')"
                    menu-props="bottom"
                    item-value="value"
                    outlined
                    rounded
                    dense
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    id="current-experience-start-year"
                    v-model="currentExperience.startYear"
                    :items="years"
                    color="accent"
                    :label="$t('Pages.Profile.start_year')"
                    menu-props="bottom"
                    item-value="value"
                    outlined
                    rounded
                    dense
                    :rules="[rules.required]"
                    @input="changeEndTimes"
                  />
                </v-col>
              </v-row>
            </div>
            <div class="wrapper-div">
              <v-row>
                <v-col cols="6">
                  <v-select
                    id="current-experience-end-month"
                    v-model="currentExperience.endMonth"
                    :disabled="currentExperience.ongoing"
                    :items="months"
                    color="accent"
                    :label="$t('Pages.Profile.end_month')"
                    menu-props="bottom"
                    item-value="value"
                    outlined
                    class="mb-4"
                    rounded
                    dense
                    :rules="currentExperience.ongoing ? [] : [rules.required, validDateRange()]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    id="current-experience-end-year"
                    v-model="currentExperience.endYear"
                    :disabled="currentExperience.ongoing"
                    :items="workedEndTimes"
                    color="accent"
                    :label="$t('Pages.Profile.end_year')"
                    menu-props="bottom"
                    item-value="value"
                    outlined
                    rounded
                    dense
                    :rules="currentExperience.ongoing ? [] : [rules.required, validDateRange()]"
                    hide-details
                    @input="currentExperience.ongoing = false"
                  />
                </v-col>
              </v-row>
              <v-textarea
                id="current-experience-description"
                v-model="currentExperience.description"
                :maxlength="300"
                :counter="300"
                color="accent"
                class="description"
                outlined
                rows="3"
                :label="$t('Pages.Profile.description')"
              />
            </div>
            <div class="mb-2">
              <transition-group
                name="slide-y-transition"
                tag="span"
              >
                <v-chip
                  v-for="(skillToAdd, index) in currentExperience.skills"
                  id="'skill-to-add-'+ index"
                  :key="'skill-' + skillToAdd"
                  class="gradient-chip margin-chip "
                  close
                  @click:close="removeSkillFromList(skillToAdd, index, 'currentExperience')"
                >
                  <v-icon left>
                    check
                  </v-icon>
                  {{ skillToAdd }}
                </v-chip>
              </transition-group>
            </div>
            <div>
              <p
                v-if="suggestions.length > 0"
                class="mb-1 "
              >
                {{ $t('Pages.Profile.suggested_skills') }}
              </p>
              <div
                v-if="suggestions.length > 0"
                class="mb-2"
              >
                <transition-group
                  name="slide-y-transition"
                  tag="span"
                >
                  <v-chip
                    v-for="skill in suggestions"
                    id="suggestion.id"
                    :key="skill.skill"
                    class="gradient-chip margin-chip"
                    @click="suggestions.splice(suggestions.indexOf(skill),1); currentExperience.skills.push(skill.skill)"
                  >
                    <v-icon left>
                      add
                    </v-icon>
                    {{ skill.skill }}
                  </v-chip>
                </transition-group>
              </div>
            </div>
          </div>
          <div
            slot="buttons"
            class="button-container"
          >
            <v-btn
              id="close-experience-btn"
              text
              rounded
              @click="experienceDialog = false"
              v-text="$t('Common.cancel')"
            />
            <v-btn
              id="save-experience-btn"
              text
              :disabled="!isFormValid"
              class="cta-btn"
              rounded
              @click="formatInput(false)"
              v-text="$t('Common.save')"
            />
          </div>
        </div>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="deleteReferenceDialog"
      max-width="500"
    >
      <CustomDialog
        title-icon="delete"
        :title="$t('Pages.Profile.delete_reference')"
        :second-title="$t('Pages.Profile.reference_will_be')"
        :action-button="{
          class: 'deleteAction',
          id: 'delete-ref-confirm-btn',
          text: $t('Common.delete')
        }"
        :close-button="{
          id: 'delete-reference-cancel-btn',
          text: $t('Common.cancel')
        }"
        @close-event="deleteReferenceDialog = false"
        @perform-action="deleteReference"
      />
    </v-dialog>
    <v-dialog v-model="addReferenceDialog">
      <div
        is="CustomDialog"
        :title="$t('Pages.Profile.add_reference')"
        title-icon="record_voice_over"
      >
        <v-form
          slot="form"
          ref="addRefForm"
          v-model="validAddRef"
          @submit.prevent="addReference()"
        >
          <div
            class="font-weight-bold text-center"
            v-text="$t('Pages.Profile.a_confirmation')"
          />
          <v-text-field
            v-model="refFirstName"
            :rules="[rules.required]"
            color="accent"
            :label="$t('Pages.Profile.ref_firstname')"
            required
          />
          <v-text-field
            v-model="refLastName"
            :rules="[rules.required]"
            color="accent"
            :label="$t('Pages.Profile.ref_lastname')"
            required
          />
          <v-text-field
            v-model="refEmail"
            :rules="[rules.required, rules.email]"
            color="accent"
            type="email"
            :label="$t('Pages.Profile.ref_email')"
            required
          />
          <v-alert
            :value="true"
            color="secondary"
            icon="info"
            outlined
            v-text="$t('Pages.Profile.ref_info')"
          />
          <div class="button-container">
            <v-btn
              id="add-ref-cancel-btn"
              text
              rounded
              @click="addReferenceDialog = false; clearAddReference()"
              v-text="$t('Common.cancel')"
            />
            <v-btn
              id="add-ref-confirm-btn"
              :loading="refLoader"
              :disabled="!validAddRef || refLoader"
              class="cta-btn"
              depressed
              rounded
              type="submit"
              v-text="$t('Common.save')"
            />
          </div>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import ProfileTitle from '@/pages/profile/edit/components/EditProfileTitles.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
import CustomDialog from '@/common/CustomDialog.vue'

import { getYear, subYears, parseISO } from 'date-fns'
import { formatDate } from '@/config/formatDate.js'
import { rules } from '@/config/validation-rules.js'
import { userHandler } from '@/config/userHandler.js'
import EventBus from '@/common/EventBus.js'
import { SET_PROFILE } from '@/store/mutations.type'

export default {
  components: {
    ProfileTitle,
    ProfileCard,
    CustomDialog,
    EmptySectionText
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    route: {
      type: String,
      default: 'profile'
    },
    createAlert: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isFormValid: true,
      userHandler,
      rules,
      deleteReferenceLoader: false,
      formatDate,
      experienceDialog: false,
      deleteExperienceDialog: false,
      showError: false,
      currentExperience: {
        startYear: '',
        startMonth: '',
        endYear: '',
        endMonth: ''
      },
      suggestions: [],
      titleList: [],
      skillList: [],
      skillExperienceSelect: [],
      autoCompleteLoader: false,
      workedEndTimes: [],
      workedStartTimes: [],
      itemToDelete: null,
      validAddRef: true,
      refFirstName: '',
      refLastName: '',
      refEmail: '',
      addReferenceExperience: null,
      deleteReferenceDialog: false,
      deleteReferenceId: null,
      addReferenceDialog: false,
      refLoader: false,
      refLoad: false,
      validDateRange: () => {
        if (!this.currentExperience.ongoing) {
          const startDate = new Date(this.currentExperience.startYear, this.currentExperience.startMonth - 1)
          const endDate = new Date(this.currentExperience.endYear, this.currentExperience.endMonth - 1)

          if (!this.currentExperience.endYear || !this.currentExperience.endMonth) {
            return this.$t('Common.end_date_required')
          }

          if (endDate < startDate) {
            return this.$t('Common.end_date_after_start')
          }
        }
        return true
      },
      months: [
        { text: this.$t('Months.january'), value: 1 },
        { text: this.$t('Months.february'), value: 2 },
        { text: this.$t('Months.march'), value: 3 },
        { text: this.$t('Months.april'), value: 4 },
        { text: this.$t('Months.may'), value: 5 },
        { text: this.$t('Months.june'), value: 6 },
        { text: this.$t('Months.july'), value: 7 },
        { text: this.$t('Months.august'), value: 8 },
        { text: this.$t('Months.september'), value: 9 },
        { text: this.$t('Months.october'), value: 10 },
        { text: this.$t('Months.november'), value: 11 },
        { text: this.$t('Months.december'), value: 12 }
      ],
      years: []
    }
  },
  computed: {
    isSaveButtonDisabled () {
      if (!this.currentExperience.jobTitle || !this.currentExperience.company) {
        return true
      }

      if (!this.currentExperience.startYear || !this.currentExperience.startMonth) {
        return true
      }

      if (!this.currentExperience.ongoing && (!this.currentExperience.endYear || !this.currentExperience.endMonth)) {
        return true
      }

      // Validate that end date is after start date when ongoing is false
      if (this.validDateRange() !== true) {
        return true
      }

      return false
    }
  },
  watch: {
  },
  created () {
    EventBus.$on('duplicateErrorsexperiences', (error) => {
      this.experienceErrors = error
    })
    EventBus.$on('savedexperiences', (keepOpen) => {
      if (!keepOpen) {
        this.experienceDialog = false
      } else {
        this.addCurrentExperience()
      }
    })
    EventBus.$on('deleteDialogexperiences', () => {
      this.deleteExperienceDialog = false
    })
  },
  beforeMount () {
    this.workedStartTimes = []
    for (let i = 0; i < 50; i++) {
      const year = getYear(subYears(new Date(), i))
      this.workedStartTimes.push(year)
    }
    this.years = this.workedStartTimes
  },
  beforeDestroy () {
    EventBus.$off('duplicateErrorsexperiences')
    EventBus.$off('savedexperiences')
    EventBus.$off('deleteDialogexperiences')
  },
  methods: {
    formatInput (keepOpen) {
      const { startYear, startMonth, endYear, endMonth, ongoing } = this.currentExperience
      this.currentExperience.startDate = new Date(Date.UTC(startYear, startMonth - 1, 1)).toISOString()
      if (endYear && endMonth && !ongoing) {
        const lastDayOfMonth = new Date(Date.UTC(endYear, endMonth, 0)).getUTCDate()
        this.currentExperience.endDate = new Date(Date.UTC(endYear, endMonth - 1, lastDayOfMonth)).toISOString()
      } else if (ongoing) {
        this.currentExperience.endDate = null
      }
      if (this.currentExperience.id) {
        this.$emit('save-content', { ...this.currentExperience, id: this.currentExperience.id }, 'experiences', keepOpen)
      } else {
        this.$emit('save-content', this.currentExperience, 'experiences', keepOpen)
      }
      this.experienceDialog = false
    },
    handleDelete () {
      this.$emit('delete-item', this.itemToDelete, 'experiences')
      this.deleteExperienceDialog = false
    },
    removeSkillFromList (skill, index, list) {
      if (index > -1) {
        this[list].skills.splice(index, 1)
      }
    },
    addSkillsFromList (e, list, currentVModel) {
      if (e === null || e === undefined) return
      if (!this[list].skills.includes(e)) {
        this[list].skills.push(e)
        this[currentVModel] = null
      }
      this.skillList = []
      this.$refs.autoCompleteExperience.reset()
      setTimeout(() => {
        this.$refs.autoCompleteExperience.reset()
        this.skillExperienceSelect = null
      }, 100)
    },
    changeEndTimes () {
      if (this.currentExperience.startYear > this.currentExperience.endYear) {
        this.currentExperience.endYear = ''
        this.workedEndTimes = []
      }
      let year = this.currentExperience.startYear
      const currentYear = new Date().getFullYear()
      for (let i = 0; i < 50; i++) {
        if (year > currentYear) break
        this.workedEndTimes.unshift(year)
        year += 1
      }
    },
    addExperience () {
      this.suggesting = false
      this.addCurrentExperience()
      this.experienceDialog = true
    },
    checkInput (e) {
      if (e === true) {
        this.currentExperience.endYear = ''
        this.currentExperience.endMonth = ''
      }
    },
    addCurrentExperience () {
      this.currentExperience = {
        company: '',
        jobTitle: '',
        description: '',
        startYear: '',
        startMonth: '',
        endYear: '',
        endMonth: '',
        ongoing: false,
        skills: [],
        isAssignment: false
      }
      if (this.profile.experiences) {
        this.currentExperience.index = this.profile.experiences.length + 1
      } else {
        this.currentExperience.index = 0
      }
    },
    addExistingExperience (experience, index) {
      const skills = []
      for (const x in experience.skills) {
        skills.push(experience.skills[x].skill)
      }
      const startYear = getYear(parseISO(experience.startDate))
      const startMonth = new Date(experience.startDate).getMonth() + 1
      const currentYear = new Date().getFullYear()
      const endYears = []
      for (let j = 0; j < 30; j++) {
        const year = startYear + j
        if (year > currentYear) {
          break
        }
        endYears.unshift(year)
      }
      this.currentExperience = {
        company: experience.company,
        jobTitle: experience.jobTitle,
        isAssignment: experience.isAssignment,
        startYear: startYear,
        startMonth: startMonth,
        endYear: experience.endDate ? getYear(parseISO(experience.endDate)) : null,
        endMonth: experience.endDate ? new Date(experience.endDate).getMonth() + 1 : null,
        ongoing: experience.ongoing,
        description: experience.description,
        index: index,
        skills: skills,
        id: experience.id
      }
      this.workedEndTimes = endYears
    },
    addReference () {
      if (this.$refs.addRefForm.validate()) {
        this.refLoader = true
        const referenceData = {
          firstName: this.refFirstName,
          lastName: this.refLastName,
          email: this.refEmail,
          approved: false,
          experienceId: this.addReferenceExperience.id
        }

        const msg = 'Confirmation email successfully sent to ' + this.refEmail
        this.$http.put(this.route + '/experiences/' + this.addReferenceExperience.id + '/reference/create', referenceData).then(
          res => {
            this.$store.commit(SET_PROFILE, res.data.profile)
            this.createAlert(msg, 'check', 'success')
            this.refLoader = false
            this.addReferenceDialog = false
            if (!this.addReferenceExperience.references) {
              this.addReferenceExperience.references = []
            }
            this.addReferenceExperience.references = res.data.references
            this.clearAddReference()
          },
          err => {
            console.error(err)
            this.$root.$emit('errorMessage', err.response)
            this.refLoader = false
          }
        )
      }
    },
    deleteReference () {
      this.deleteReferenceLoader = true
      const i = this.addReferenceExperience.references.map(item => item.id).indexOf(this.deleteReferenceId)
      this.addReferenceExperience.references.splice(i, 1)
      this.$http.delete(this.route + '/references/' + this.deleteReferenceId).then(
        res => {
          this.addReferenceExperience.references = res.data.references
          this.deleteReferenceLoader = false
          this.deleteReferenceDialog = false
        },
        err => {
          console.error(err)
          this.deleteReferenceLoader = false
          this.$root.$emit('errorMessage', err.response)
        }
      )
    },
    clearAddReference () {
      this.$refs.addRefForm.reset()
    },
    getSkillsFromSkillsArray (skills, basedOnOthers) {
      const arr = []
      for (var i in skills) {
        arr.push(skills[i].skill)
      }
    },
    getExperienceDates (experience) {
      const formatDate = (date) => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const d = new Date(date)
        const month = monthNames[d.getMonth()]
        const year = d.getFullYear()
        return `${month} ${year}`
      }

      let dateString = ''
      if (experience.startDate) {
        dateString += formatDate(experience.startDate)
      }
      if (experience.endDate) {
        dateString += ' - ' + formatDate(experience.endDate)
      } else if (experience.ongoing) {
        dateString += ' - ' + this.$t('Pages.Profile.present')
      }
      return dateString
    },
    formatTimestamp (timestamp) {
      const date = new Date(timestamp)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }
}
</script>
